import { EPOCH_INTERVAL, BLOCK_RATE_SECONDS, addresses } from "../constants";
import { BigNumber, ethers, Contract } from "ethers";
import axios from "axios";
import PairContractABIJson from "../abi/PairContract.json";
import RedeemHelperABIJson from "../abi/RedeemHelper.json";
import Erc20Json from '../abi/ERC20.json';

import { ohm_dai } from "./AllBonds";
import { JsonRpcSigner, StaticJsonRpcProvider } from "@ethersproject/providers";
import { IBaseAsyncThunk } from "src/slices/interfaces";
import { PairContract, RedeemHelper } from "../typechain";
import { fetchToken } from "@wagmi/core";

const erc20ABI = Erc20Json.abi;
const PairContractABI = PairContractABIJson.abi;
const RedeemHelperABI = RedeemHelperABIJson.abi;

export async function getMarketPrice({ networkID, provider }: IBaseAsyncThunk) {
    // console.error(new Error());
    // console.log('%c [debug]networkID:'+networkID,'color:#00FF88')
    const ohm_dai_address = addresses[networkID].OHM_USDT_PAIR;
    // const ohm_dai_address = ohm_dai.getAddressForReserve(networkID);
    // const ohm_dai_address = ohm_dai.getAddressForReserve(networkID);
    // console.log("loadMarketPrice ohm_dai_address", networkID, ohm_dai_address);
    const pairContract = new ethers.Contract(ohm_dai_address, PairContractABI, provider) as PairContract;
    const reserves = await pairContract.getReserves();
    const token0 = await pairContract.token0();
    // console.log("loadMarketPrice token0", token0, token0 == addresses[networkID].OHM_ADDRESS, reserves);
    let marketPrice = 0;
    //   if(token0 !== addresses[networkID].OHM_ADDRESS) {
    if (reserves[0].toString() === "0" || reserves[1].toString() === "0") {
    } else {
        marketPrice =
            token0 == addresses[networkID].OHM_ADDRESS
                ? Number(reserves[1].toString()) / Number(reserves[0].toString())
                : Number(reserves[0].toString()) / Number(reserves[1].toString());
    }
    //
    // }
    // console.log(ohm_dai_address, "ohm dai lp", marketPrice);

    return marketPrice;
}

/**
 * gets price of token from coingecko
 * @param tokenId STRING taken from https://www.coingecko.com/api/documentations/v3#/coins/get_coins_list
 * @returns INTEGER usd value
 */
export async function getTokenPrice(tokenId = "olympus") {
    let resp;
    try {
        resp = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenId}&vs_currencies=usd`);
        return resp.data[tokenId].usd;
    } catch (e) {
        // console.log("coingecko api error: ", e);
    }
}

export function shorten(str: string) {
    if (str.length < 10) return str;
    return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;
}

export function formatCurrency(c: number, precision = 0) {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
    }).format(c);
}

export function trim2(number = 0, precision = 0) {
    // why would number ever be undefined??? what are we trimming?
    number = Number(number);
    if (isNaN(number)) return 0;
    if (number >= 1e6) {
        // 大於或等於一百萬
        return (number / 1e6).toFixed(1).replace(/\.0$/, '') + 'm';
    } else if (number >= 1e3) {
        // 大於或等於一千
        return (number / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
        if (Math.trunc(number) == number) return Math.trunc(number);
        return number.toFixed(precision)
    }
}

export function trim(number = 0, precision = 0) {
    // why would number ever be undefined??? what are we trimming?
    const array = number.toString().split(".");
    if (array.length === 1) return number.toString();
    if (precision === 0) return array[0].toString();
  
    const poppedNumber = array.pop() || "0";
    array.push(poppedNumber.substring(0, precision));
    const trimmedNumber = array.join(".");
    return trimmedNumber;
  }


export function getRebaseBlock(currentBlock: number) {
    return currentBlock + EPOCH_INTERVAL - (currentBlock % EPOCH_INTERVAL);
}

export function secondsUntilBlock(startBlock: number, endBlock: number) {
    const blocksAway = endBlock - startBlock;

    const secondsAway = blocksAway * BLOCK_RATE_SECONDS;

    return secondsAway;
}

export function prettyVestingPeriod(currentBlock: number, vestingBlock: number) {
    if (vestingBlock === 0) {
        return "";
    }

    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    if (seconds < 0) {
        return "Fully Vested";
    }
    return prettifySeconds(seconds);
}

export function prettifySeconds(seconds: number, resolution?: string) {
    if (seconds !== 0 && !seconds) {
        return "";
    }

    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);

    if (resolution === "day") {
        return d + (d == 1 ? " day" : " days");
    }

    const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    const hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";

    let result = dDisplay + hDisplay + mDisplay;

    if (mDisplay === "") {
        result = result.slice(0, result.length - 2);
    }

    return result;
}

// TS-REFACTOR-NOTE - Used for:
// AccountSlice.ts, AppSlice.ts, LusdSlice.ts
export function setAll(state: any, properties: any) {
    if (properties) {
        const props = Object.keys(properties);
        props.forEach(key => {
            state[key] = properties[key];
        });
    }
}

export function contractForRedeemHelper({
    networkID,
    provider,
}: {
    networkID: number;
    provider: StaticJsonRpcProvider | JsonRpcSigner;
}) {
    return new ethers.Contract(
        addresses[networkID].REDEEM_HELPER_ADDRESS as string,
        RedeemHelperABI,
        provider,
    ) as RedeemHelper;
}

/**
 * returns false if SafetyCheck has fired in this Session. True otherwise
 * @returns boolean
 */
export const shouldTriggerSafetyCheck = () => {
    const _storage = window.sessionStorage;
    const _safetyCheckKey = "-oly-safety";
    // check if sessionStorage item exists for SafetyCheck
    if (!_storage.getItem(_safetyCheckKey)) {
        _storage.setItem(_safetyCheckKey, "true");
        return true;
    }
    return false;
};

/**
 * returns unix timestamp for x minutes ago
 * @param x minutes as a number
 */
export const minutesAgo = (x: number) => {
    const now = new Date().getTime();
    return new Date(now - x * 60000).getTime();
};

/**
 * subtracts two dates for use in 33-together timer
 * param (Date) dateA is the ending date object
 * param (Date) dateB is the current date object
 * returns days, hours, minutes, seconds
 * NOTE: this func previously used parseInt() to convert to whole numbers, however, typescript doesn't like
 * ... using parseInt on number params. It only allows parseInt on string params. So we converted usage to
 * ... Math.trunc which accomplishes the same result as parseInt.
 */
export const subtractDates = (dateA: Date, dateB: Date) => {
    let msA: number = dateA.getTime();
    let msB: number = dateB.getTime();

    let diff: number = msA - msB;

    let days: number = 0;
    if (diff >= 86400000) {
        days = Math.trunc(diff / 86400000);
        diff -= days * 86400000;
    }

    let hours: number = 0;
    if (days || diff >= 3600000) {
        hours = Math.trunc(diff / 3600000);
        diff -= hours * 3600000;
    }

    let minutes: number = 0;
    if (hours || diff >= 60000) {
        minutes = Math.trunc(diff / 60000);
        diff -= minutes * 60000;
    }

    let seconds: number = 0;
    if (minutes || diff >= 1000) {
        seconds = Math.trunc(diff / 1000);
    }
    return {
        days,
        hours,
        minutes,
        seconds,
    };
};

export const toBN = (num: number) => {
    return BigNumber.from(num);
};

export const bnToNum = (bigNum: BigNumber) => {
    return Number(bigNum.toString());
};

export const formatMoney = (money: number, decimals = 2) => {
    const result = money.toFixed(decimals).replace(/\B(?=(\d{3})+\b)/g, ",");
    return result;
};

export const toolNumber = (num_str: any) => {
    num_str = num_str.toString();
    if (num_str.indexOf("+") != -1) {
        num_str = num_str.replace("+", "");
    }
    if (num_str.indexOf("E") != -1 || num_str.indexOf("e") != -1) {
        let resValue = "",
            power: any = "",
            result = null,
            dotIndex = 0,
            resArr: string[] = [],
            sym = "";
        var numStr = num_str.toString();
        if (numStr[0] == "-") {
            numStr = numStr.substr(1);
            sym = "-";
        }
        if (numStr.indexOf("E") != -1 || numStr.indexOf("e") != -1) {
            var regExp = new RegExp("^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$", "ig");
            result = regExp.exec(numStr);
            if (result != null) {
                resValue = result[2];
                power = result[5];
                result = null;
            }
            if (!resValue && !power) {
                return false;
            }
            dotIndex = resValue.indexOf(".") == -1 ? 0 : resValue.indexOf(".");
            resValue = resValue.replace(".", "");
            resArr = resValue.split("");
            if (Number(power) >= 0) {
                var subres = resValue.substr(dotIndex);
                power = Number(power);
                for (var i = 0; i <= power - subres.length; i++) {
                    resArr.push("0");
                }
                if (power - subres.length < 0) {
                    resArr.splice(dotIndex + power, 0, ".");
                }
            } else {
                power = power.replace("-", "");
                power = Number(power);
                for (var i = 0; i < power - dotIndex; i++) {
                    resArr.unshift("0");
                }
                var n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
                resArr.splice(n, 0, ".");
            }
        }
        resValue = resArr.join("");

        return sym + resValue;
    } else {
        return num_str;
    }
};

export const bigNumber = (num: any, decimals = 18) => {
    return BigNumber.from(10).pow(decimals).mul(BigNumber.from(num));
};

export const fetchLotteryDataFromURL = async (url: string, roundType: string, roundSequence?: number) => {
    const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}/${url}?roundType=${roundType}${url !== "lottery/lotteryList" ? `&roundSequence=${roundSequence}` : ""
        }`,
        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
        },
    );
    const data = await response.json();
    return data;
};

export const fetchQueryStakingStatus = async (address: string) => {
    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/comm/queryStakingStatus?member=${address}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();
    return data;
};

export const fetchTeamWork = async (address: string) => {
    // console.log('[debug]url:', `${process.env.REACT_APP_ADMIN_API_URL}/user/${address}`);
    const response = await fetch(`${process.env.REACT_APP_ADMIN_API_URL}/user/${address}`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();
    return data;
};

export const getTokenDecimals = async (tokenAddress: string, chainId: number) => {
    const { decimals } = await fetchToken({
        address: tokenAddress as `0x${string}`,
        chainId: chainId,
    });
    // console.log('[debug]getTokenDecimals:', decimals);
    return decimals;
};

export const getTokenDecimals2 = async (tokenAddress: string, provider: any) => {
    const tokenContract = new Contract(tokenAddress as `0x${string}`, erc20ABI, provider);
    return tokenContract.decimals()
    // const { decimals } = await fetchToken({
    //   address: tokenAddress as `0x${string}`,
    // });
};



export function toFullNumberString(number: any) {
    // 使用正则表达式检查是否为科学记数法
    const isExponential = number.toString().match(/^(\d+(\.\d+)?)(e[+-]?\d+)$/);
    if (isExponential) {
        console.log("getLotteryData isExponential", Number(number).toFixed(0));

        // 转换为完整数字字符串
        return Number(number).toFixed(0);
    }
    // 如果不是科学记数法，直接返回
    return number.toString();
}

export function ellTx(tx: string) {
    return tx.substring(0, 5) + '...' + tx.slice(-4);
}

const PriceFeedABI = `
[{ "inputs": [], "name": "latestRoundData",
   "outputs": [{ "internalType": "uint80", "name": "roundId", "type": "uint80" }, 
   { "internalType": "int256", "name": "answer", "type": "int256" },
    { "internalType": "uint256", "name": "startedAt", "type": "uint256" },
     { "internalType": "uint256", "name": "updatedAt", "type": "uint256" }, 
     { "internalType": "uint80", "name": "answeredInRound", "type": "uint80" }], 
     "stateMutability": "view", "type": "function" }]
`

export async function getWETHPrice({networkID,provider}:any){
    const PriceFeedContract = new Contract(addresses[networkID]['ETH_PRICEFEED_ADDRESS'],PriceFeedABI,provider);
    const [,answer,] = await PriceFeedContract.lastRoundData();
    return answer;
}

export async function getBTCPrice({networkID,provider}:any){
    const PriceFeedContract = new Contract(addresses[networkID]['BTC_PRICEFEED_ADDRESS'],PriceFeedABI,provider);
    const [,answer,] = await PriceFeedContract.lastRoundData();
    return answer;
}