import { t, Trans } from "@lingui/macro";
import {
    Box,
    Paper,
    Zoom,
    Typography,
    
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useMobile } from "src/hooks";


export const WebKeyBanner = ()=>{
    const { isSmallScreen } = useMobile();
    return (
        <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            mb: isSmallScreen ? 1.875 : 3.125,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                position: "relative",
                                p: 3.75,
                            }}
                        >
                            <Box
                                sx={{
                                    position: "relative",
                                    zIndex: 1,
                                }}
                                display="flex"
                                flexDirection={isSmallScreen ? "column" : "row"}
                            >
                                <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? "row" : "column"}
                                    sx={{
                                        width: isSmallScreen ? "100%" : "240px",
                                        mr: isSmallScreen ? 0 : 5.25,
                                        mb: isSmallScreen ? 2 : 0,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            mb: isSmallScreen ? 0 : 2.5,
                                            width: isSmallScreen ? "100%" : "50px",
                                            height: isSmallScreen ? "32px" : "50px",
                                            maxWidth: isSmallScreen ? "32px" : "50px",
                                            borderRadius: "50%",
                                            border: isSmallScreen
                                                ? "1.829px solid rgba(28, 30, 33, 0.60)"
                                                : "2.857px solid rgba(28, 30, 33, 0.60)",
                                            background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                                            boxShadow: isSmallScreen
                                                ? "1.52381px 3.04762px 6.85714px 0px #070707, -2.28571px -1.52381px 12.19048px 0px #455059"
                                                : "2.38095px 4.7619px 10.71428px 0px #070707, -3.57143px -2.38095px 19.04762px 0px #455059",
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                background: "linear-gradient(136deg, #131416 9.64%, #32373A 89.75%)",
                                                boxShadow: isSmallScreen
                                                    ? "0px 0px 3.04762px 0px #33383C inset"
                                                    : "0px 0px 4.7619px 0px #33383C inset",
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "12.8px" : "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                01
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            ml: isSmallScreen ? 1.875 : 0,
                                        }}
                                    >
                                        <Box sx={{ mb: 1.75 }}>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <Trans>Ecosystem Staking</Trans>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: "#84898D",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                <Trans>1. Each unit of Ecosystem staking is valued at 1200 DAI</Trans>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? "row" : "column"}
                                    sx={{ width: isSmallScreen ? "100%" : "220px", mr: isSmallScreen ? 0 : 8, mb: isSmallScreen ? 2 : 0 }}
                                >
                                    <Box
                                        sx={{
                                            mb: isSmallScreen ? 0 : 2.5,
                                            width: isSmallScreen ? "100%" : "50px",
                                            height: isSmallScreen ? "32px" : "50px",
                                            maxWidth: isSmallScreen ? "32px" : "50px",
                                            borderRadius: "50%",
                                            border: isSmallScreen
                                                ? "1.829px solid rgba(28, 30, 33, 0.60)"
                                                : "2.857px solid rgba(28, 30, 33, 0.60)",
                                            background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                                            boxShadow: isSmallScreen
                                                ? "1.52381px 3.04762px 6.85714px 0px #070707, -2.28571px -1.52381px 12.19048px 0px #455059"
                                                : "2.38095px 4.7619px 10.71428px 0px #070707, -3.57143px -2.38095px 19.04762px 0px #455059",
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                background: "linear-gradient(136deg, #131416 9.64%, #32373A 89.75%)",
                                                boxShadow: isSmallScreen
                                                    ? "0px 0px 3.04762px 0px #33383C inset"
                                                    : "0px 0px 4.7619px 0px #33383C inset",
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "12.8px" : "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                02
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            ml: isSmallScreen ? 1.875 : 0,
                                        }}
                                    >
                                        <Box sx={{ mb: 1.75, pt: isSmallScreen ? 0.75 : 0 }}>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <Trans>Staking period</Trans>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: "#84898D",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                <Trans>
                                                The ecosystem staking period is 5 weeks
                                                </Trans>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? "row" : "column"}
                                    sx={{ width: isSmallScreen ? "100%" : "220px", mb: isSmallScreen ? 2 : 0 }}
                                >
                                    <Box
                                        sx={{
                                            mb: isSmallScreen ? 0 : 2.5,
                                            width: isSmallScreen ? "100%" : "50px",
                                            height: isSmallScreen ? "32px" : "50px",
                                            maxWidth: isSmallScreen ? "32px" : "50px",
                                            borderRadius: "50%",
                                            border: isSmallScreen
                                                ? "1.829px solid rgba(28, 30, 33, 0.60)"
                                                : "2.857px solid rgba(28, 30, 33, 0.60)",
                                            background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                                            boxShadow: isSmallScreen
                                                ? "1.52381px 3.04762px 6.85714px 0px #070707, -2.28571px -1.52381px 12.19048px 0px #455059"
                                                : "2.38095px 4.7619px 10.71428px 0px #070707, -3.57143px -2.38095px 19.04762px 0px #455059",
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                background: "linear-gradient(136deg, #131416 9.64%, #32373A 89.75%)",
                                                boxShadow: isSmallScreen
                                                    ? "0px 0px 3.04762px 0px #33383C inset"
                                                    : "0px 0px 4.7619px 0px #33383C inset",
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "12.8px" : "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                03
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            ml: isSmallScreen ? 1.875 : 0,
                                        }}
                                    >
                                        <Box sx={{ mb: 1.75, pt: isSmallScreen ? 0.75 : 0 }}>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <Trans>Inventory</Trans>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: "#84898D",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                <Trans>
                                                Staking requires available WebKey inventory.Inventory quantity is updated periodically.
                                                </Trans>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {!isSmallScreen ? (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 0,
                                        left: "0",
                                        top: "53px",
                                        width: "100%",
                                        height: "1px",
                                        background: "#0B0C0A",
                                        boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 0,
                                        left: "42px",
                                        top: "0",
                                        width: "1px",
                                        height: "100%",
                                        background: "#0B0C0A",
                                        boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                                    }}
                                />
                            )}
                        </Box>
                    </Paper>
                </Zoom>
    )
}