import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { Box } from "@material-ui/core";
import { trim, formatCurrency } from "../../../../helpers";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip.jsx";
import { Typography } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { allBondsMap } from "src/helpers/AllBonds";

export const Metric = props => (
  <Box
    sx={{
      width: "33.3%",
    }}
    display="flex"
    alignItems={"center"}
    justifyContent={"center"}
    flexDirection={"column"}
  >
    {props.children}
  </Box>
);

Metric.Value = props => (
  <Typography
    sx={{
      color: "#C7C8CC",
      fontFamily: "AGaramond LT",
      fontSize: "21px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    }}
  >
    {props.children || <Skeleton type="text" />}
  </Typography>
);

Metric.Title = props => (
  <Typography
    sx={{
      color: "#868B8F",
      fontFamily: "AGaramond LT",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    }}
    align="center"
  >
    {props.children}
  </Typography>
);

export const MarketCap = () => {
  const marketCap = useSelector(state => state.app.marketCap);
  const marketValue = useSelector(state => state.app.marketValue);
  return (
    <Metric className="market">
      <Metric.Title>
        <Trans>Market Cap</Trans>
      </Metric.Title>
      <Metric.Value>{marketValue && formatCurrency(marketValue, 2)}</Metric.Value>
    </Metric>
  );
};

export const OHMPrice = () => {
  const marketPrice = useSelector(state => state.app.marketPrice);

  return (
    <Metric className="price">
      <Metric.Title>
        <Trans>LGNS Price</Trans>
      </Metric.Title>
      <Metric.Value>{marketPrice && formatCurrency(marketPrice, 2)}</Metric.Value>
    </Metric>
  );
};
export const TotalMinting = () => {
  // const marketPrice = useSelector(state => state.app.marketPrice);
  const totalSupply = useSelector(state => state.app.totalSupply);
  return (
    <Metric className="price">
      <Metric.Title>
        <Trans>Total minting</Trans>
      </Metric.Title>
      <Metric.Value>{totalSupply && trim(totalSupply, 4)}</Metric.Value>
    </Metric>
  );
};

export const SOHMPrice = () => {
  const marketPrice = useSelector(state => state.app.marketPrice);

  return (
    <Metric className="price">
      <Metric.Title>
        <Trans>sLGNS Price</Trans>
      </Metric.Title>
      <Metric.Value>{marketPrice && formatCurrency(marketPrice, 2)}</Metric.Value>
    </Metric>
  );
};

export const CircSupply = () => {
  const circSupply = useSelector(state => state.app.circSupply);
  const totalSupply = useSelector(state => state.app.totalSupply);

  const isDataLoaded = circSupply && totalSupply;

  return (
    <Metric className="circ">
      <Metric.Title>
        <Trans>Circulating Supply (total)</Trans>
      </Metric.Title>
      <Metric.Value>{isDataLoaded && parseInt(circSupply) + " / " + parseInt(totalSupply)}</Metric.Value>
    </Metric>
  );
};

export const TotalSupply = () => {
  const totalSupply = useSelector(state => state.app.totalSupply);

  return (
    <Metric className="circ">
      <Metric.Title>
        <Trans>Total Supply</Trans>
      </Metric.Title>
      <Metric.Value>{totalSupply && parseInt(totalSupply)}</Metric.Value>
    </Metric>
  );
};

export const BackingPerOHM = () => {
  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          tokenBalances += state.bonding[bond].purchased;
        }
      }
      if (tokenBalances) {
        return tokenBalances;
      } else {
        return 0;
      }
    }
  });

  const totalSupply = useSelector(state => {
    return state.app.totalSupply || 0;
  });

  const daoBal = useSelector(state => {
    return state.app.daoBal;
  });
  const totalReserves = useSelector(state => {
    return state.app.totalReserves;
  });
  const backingPerOhm = totalReserves / totalSupply;
  return (
    <Metric className="bpo">
      <Metric.Title>
        <Trans>Backing per LGNS</Trans>
      </Metric.Title>
      <Metric.Value>{!isNaN(backingPerOhm) && formatCurrency(backingPerOhm, 2)}</Metric.Value>
    </Metric>
  );
};

export const CurrentIndex = () => {
  const currentIndex = useSelector(state => state.app.currentIndex);

  return (
    <Metric className="index">
      <Metric.Title>
        <Trans>Current Index</Trans>
        <InfoTooltip
          message={t`The current index tracks the amount of sLGNS accumulated since the betting started. Basically, how many sLGNS a person would have if they wagered and held one LGNS from day one.`}
        />
      </Metric.Title>
      <Metric.Value>{currentIndex && trim(currentIndex, 2) + " LGNS"}</Metric.Value>
    </Metric>
  );
};

export const WSOHMPrice = () => {
  const wsOhmPrice = useSelector(state => state.app.marketPrice * state.app.currentIndex);

  return (
    <Metric className="wsoprice">
      <Metric.Title>
        <Trans>wsTAO Price</Trans>
        <InfoTooltip
          message={
            "wsOHM = sLGNS * index\n\nThe price of wsOHM is equal to the price of LGNS multiplied by the current index"
          }
        />
      </Metric.Title>
      <Metric.Value>{wsOhmPrice && formatCurrency(wsOhmPrice, 2)}</Metric.Value>
    </Metric>
  );
};
