import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import { Box, Grid, Container, Typography } from '@mui/material';

import { t, Trans } from "@lingui/macro";

const CountdownTimer = ({ targetDate,onReachEnd }) => {
    const calculateTimeLeft = useCallback(() => {
        const difference = +new Date(targetDate) - +new Date();
        let timeDifference = {};

        if (difference > 0) {
            timeDifference = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            onReachEnd&&onReachEnd();
            timeDifference = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
        return timeDifference;
    }, [targetDate]);

    const [remainingTime, setRemainingTime] = useState(calculateTimeLeft());

    const labels = {
        days: 'd',
        hours: 'h',
        minutes: 'm',
        seconds: 's',
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [calculateTimeLeft]);
    
    return (
        <Container>
            <Box mt={5} sx={{display:'flex', justifyContent:'center'}}>
                <Grid container spacing={0} justifyContent="center" sx={{ width: { xs: '350px', md: '50vw' } }}>
                    {Object.keys(remainingTime).map((interval) => (
                        <Grid item key={interval} xs={3}>
                            <Box
                                sx={{
                                    paddingX: 'auto',
                                    borderRadius: '8px',
                                    minWidth: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    backgroundColor:'#18191D',
                                    margin:1

                                }}
                            >
                                <Typography variant="h4" color={'#868B8F'}>
                                    {remainingTime[interval]}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        background: 'linear-gradient(to top, rgba(44, 44, 44, 1), rgba(109, 109, 109, 0.8))',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        display: 'inline-block',
                                    }}>
                                     {interval==='days'&&<Trans>days</Trans>}
                                     {interval==='hours'&&<Trans>hours</Trans>}
                                     {interval==='minutes'&&<Trans>minutes</Trans>}
                                     {interval==='seconds'&&<Trans>seconds</Trans>}
                                      {/* {t(interval)} */}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container >
    );
};

CountdownTimer.propTypes = {
    targetDate: PropTypes.instanceOf(Date).isRequired,
};

export default CountdownTimer;
